// validationSchema.js
import Joi from 'joi';


// Subcategory validation schema
const subCategorySchema = Joi.object({
    title: Joi.string()
      .min(2)
      .required()
      .messages({
        'string.min': 'Title must be at least 2 characters long',
        'any.required': 'Title is required',
      }),
  });
  

export default subCategorySchema;
