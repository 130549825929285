import React, { useState, useEffect } from "react";
import "./themes/main.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "./components/pages/Login";

import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import OTP from "./components/pages/OTP";
import Layout from './components/layout/Layout';
import Dashboard from "./components/pages/Dashboard";
import Users from "./components/pages/Users";
import Adduser from "./components/pages/Adduser";
import Edituser from "./components/pages/Edituser";
import Category from "./components/pages/Category";
import Subcategory from "./components/pages/Subcategory";
import ProfileSettings from "./components/pages/ProfileSettings";
import Viewuser from "./components/pages/Viewuser";
import Notifications from "./components/pages/Notifications";
import Allpages from "./components/pages/Allpages";
import Advertisement from "./components/pages/Advertisement";
import Business from "./components/pages/Business";
import Banner from "./components/pages/Banner";
import Addbusiness from "./components/pages/Addbusiness";
import Editbusiness from "./components/pages/Editbusiness";
import Viewbusiness from "./components/pages/Viewbusiness";
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFoundPage from "./components/pages/Notfound";
import PrivateRoutes from "./components/pages/PrivateRouting";
// require('dotenv').config()


function App() {
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setLoading(false);
  //   // setTimeout(() => {
  //   //   setLoading(false);
  //   // }, 1000);
  // }, []);

  return (
    <>
    {loading ? (
      <div className="loaderdiv">
        <Sentry color="#000080" size={40} speed={1} animating={true} />
      </div>
    ) : (
      <Router basename={process.env.REACT_APP_BASENAME}>
      {/* <Router>  */}
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/OTP" element={<OTP />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/Dashboard" element={ <PrivateRoutes>
           <Layout title={"Dashboard"}><Dashboard /></Layout> </PrivateRoutes>} />
          <Route path="/Users" element={<Layout title={"Users"}><Users /></Layout>} />
          <Route path="/Adduser" element={<Layout title={"Add User"}><Adduser /></Layout>} />
          <Route path="/Edituser" element={<Layout title={"Edit User"}><Edituser /></Layout>} />
          <Route path="/Category" element={<Layout title={"Categories"}><Category /></Layout>} />
          <Route path="/Viewuser" element={<Layout title={"User Details"}><Viewuser/></Layout>} />
          <Route path="/Notifications" element={<Layout title={"Notifications"}><Notifications/></Layout>} />
          <Route path="/Subcategory/:id" element={<Layout title={"Subcategory"}><Subcategory /></Layout>} />
          <Route path="/Allpages" element={<Layout title={"All Pages"}><Allpages /></Layout>} />
          <Route path="/Advertisement" element={<Layout title={"Advertisement"}><Advertisement /></Layout>} />
          <Route path="/Banner" element={<Layout title={"Banners"}><Banner /></Layout>} />
          <Route path="/Business" element={<Layout title={"Business"}><Business /></Layout>} />
          <Route path="/Addbusiness" element={<Layout title={"Add Business"}><Addbusiness /></Layout>} />
          <Route path="/Editbusiness/:id" element={<Layout title={"Edit Business"}><Editbusiness /></Layout>} />
          <Route path="/Viewbusiness/:id" element={<Layout title={"Business Details"}><Viewbusiness /></Layout>} />
          <Route path="/ProfileSettings" element={<Layout title={"Profile Settings"}><ProfileSettings /></Layout>} />
        </Routes>
      </Router>
    )}
  </>
  );
}

export default App;
