import React from "react";
import { Dropdown, Breadcrumb, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Avatar from "../assets/img/userport.png";
import { useQuery } from "@tanstack/react-query";
import { getadminDetails } from "../axiosConfig/api";
import { Image_endpoint } from "../../local";
import Cookies from 'js-cookie';

const Header = ({ title }) => {
    const navigate = useNavigate();

    const url = "adminProfile";
    const { data, error, isLoading } = useQuery({
        queryKey: ['admindetails', url],
        queryFn: () => getadminDetails(url),
    });

    const handleLogout = () => {
        // Clear all cookies
        Object.keys(Cookies.get()).forEach((cookie) => {
            Cookies.remove(cookie);
        });

        // Redirect to login screen
        navigate('/');
    };

    return (
        <header className="header">
            <Row>
                <Col xs={6} className="align-self-center">
                    <div className="mainbreadcrumb d-none d-md-block">
                        <Breadcrumb>
                            <Breadcrumb.Item href="#">Pages</Breadcrumb.Item>
                            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </Col>
                <Col xs={6} className="text-end">
                    <div className='headerright' style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Link underline="none" className="mx-3 linksheader" to="/Notifications">
                            <Icon icon="solar:bell-outline" />
                        </Link>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className="p-0">
                                <img alt="" src={data?.profilePic != null ? Image_endpoint + data?.profilePic : Avatar} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/ProfileSettings">Profile Settings</Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Col>
            </Row>
        </header>
    );
};

export default Header;
