import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button ,
} from 'react-bootstrap';
import logo from "../assets/img/logo.svg";
import { Link } from 'react-router-dom';
import { useMutation } from "@tanstack/react-query";
import Cookie from "js-cookie"
import { AuthPost } from "../axiosConfig/promise";
import toast, { Toaster } from "react-hot-toast";
import otpSchema from "../formValidation/otpSchema";
import { ThreeCircles } from "react-loader-spinner";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [email,setEmail]=useState(null)
  const [hash,sethash]=useState(null)
  const [form, setform] = useState({ otp: '' });

  useEffect(() => {
    try {
      let token = Cookie.get("resetToken");
      let email = Cookie.get("email");

      if (email) {
        setEmail(email);
      }
      if (token) {
        sethash(token);
      }
    } catch (error) {
      console.error("Error retrieving cookies:", error);
    }
  }, []);

  const mutation = useMutation({
    mutationFn:(params) => AuthPost(params.url, { OTP: params.OTP,email:params?.email,hash:params?.hash}),
    onSuccess: (data) => {
      // Store the token and fetch user details
      console.log(data,"is here --- >")
      if(data.status){
        toast.success('OTP verifcation successfull!')
        Cookie.set("resetToken",data?.data?.userToken)
        navigate("/ResetPassword")
  return
      }

      toast.success(data?.message)
return
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error('error:', error.message);
      toast.error(error.message)

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: 'login', // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });
  
  

  const validate = (formData) => {
    const { error } = otpSchema.validate(formData, { abortEarly: false });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
        errors[item.path[0]] = item.message;
    }
    return errors;
};


const handleSubmit = (e) => {
  e.preventDefault();
  const formErrors = validate(form);
  console.log(formErrors,"formErrors==>")
  setErrors(formErrors);

  if (Object.keys(formErrors).length === 0) {
    let parms = {
      url:"adminVerifyOTP",
      OTP:form?.otp,
      email:email,
      hash:hash
    }
  mutation.mutate(parms);
    // Submit the form
    // console.log('Form submitted successfully', values);
  }
};

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>OTP Verification</h2>
              <p>Please enter the  6 digit verification code sent on your registered email address.</p>
            </div>
            <Form className="">
              <Form.Group className="mb-4 form-group">
                <Form.Label>OTP</Form.Label>
                <Form.Control value={form?.otp} onChange={(e)=>setform({...form,otp:e.target.value})} type="text" placeholder="Enter your OTP"/>
                <p style={{fontSize:12,color:'red'}}>
                {errors.otp && <span>{errors.otp}</span>}
                </p>
              </Form.Group>
              <Link>
              <Button className="btn btn-primary w-100 mt-3" onClick={handleSubmit}>
  {mutation.isPending ? (
    <ThreeCircles
      visible={true}
      height="30"
      width="30" // Adjust the height and width as needed
      color="white"
      ariaLabel="three-circles-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  ) : (
    "Proceed"
  )}
</Button>
                </Link>
              <p className="formfooter text-center mt-3">Back To <Link to="/">Login</Link></p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link><img src={logo} alt=""/></Link>
          </div>
        </Col>
      </Row>
      <Toaster />
    </React.Fragment>
  );
}