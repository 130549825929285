import Joi from 'joi';

const businessValidationSchema = Joi.object({
  businessLogo: Joi.any().optional(),
  businessName: Joi.string()
    .required()
    .messages({
      'string.empty': 'Business name is required',
      'any.required': 'Business name is required',
    }),
  businessPhoneNumber: Joi.string()
    .required()
    .messages({
      'string.empty': 'Business phone number is required',
      'any.required': 'Business phone number is required',
    }),
  countryCode: Joi.any().optional(),
  businessDescription: Joi.any().optional(),
  plan: Joi.string().optional(),
  businessCertificate: Joi.any().optional(),
  businessCategory: Joi.string()
    .required()
    .messages({
      'string.empty': 'Business category is required',
      'any.required': 'Business category is required',
    }),
  businessSubCategory: Joi.any().optional(),
  businessHour: Joi.any().optional(),
  businessPic: Joi.any().optional(),
  businessLocation: Joi.array().optional(),
  isOpen24Hours: Joi.boolean().optional(),
  times: Joi.any().optional(),
  businessAddress: Joi.any().optional(),
  profileStep: Joi.number().optional(),
  whatsappLink: Joi.string().allow('').optional(),
  telegramLink: Joi.string().allow('').optional(),
  instagramLink: Joi.string().allow('').optional(),
  fullName: Joi.string()
    .required()
    .messages({
      'string.empty': 'Full name is required',
      'any.required': 'Full name is required',
    }),
    email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': 'Email is required',
      'string.email': 'Please enter a valid email address',
    }),
  phoneNumber: Joi.string().optional(),
  businessImage: Joi.any().allow(),
  password: Joi.string().optional(),
  _id: Joi.string().optional(),
  userId: Joi.string().optional(),
  accountStatus: Joi.string().optional(),
  createdAt: Joi.date().optional(),
  updatedAt: Joi.date().optional(),
  __v: Joi.number().optional(),
  businesscategories: Joi.array().optional(),
  businesssubcategories: Joi.array().optional(),
  locationName:Joi.any().allow(),

});

export default businessValidationSchema;
