import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import avatar1 from "../assets/img/userport.png";
import avatar2 from "../assets/img/avatars/2.png";
import avatar3 from "../assets/img/avatars/3.png";
import avatar4 from "../assets/img/avatars/4.png";
import avatar5 from "../assets/img/avatars/5.png";
// import { AuthgetApi } from "../axiosConfig/promise";
// import { useQuery } from "@tanstack/react-query";
import { Image_endpoint } from "../../local";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AuthPostdelete, AuthgetApi } from "../axiosConfig/promise";
import toast, { Toaster } from "react-hot-toast";
export default function Business() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchText, setSearchText] = useState("");
  const url = `adminBusinessList`;

  const {
    data: businessList,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["businesslist", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });
  const mutationdelete = useMutation({
    mutationFn: (params) => AuthPostdelete(params.url),
    onSuccess: (data) => {
      // Store the token and fetch user details
      
      if (data.status) {
        toast.success("Business deleted successfully!");
        queryClient.invalidateQueries("businesslist"); // Invalidate and refetch admin data if you have a query for it
        // setErrors({})
        //   setShow(false)
        // handleClose()
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error(" error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "notficationlist", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });
  const handleDelete = (businessId) => {
    console.log("handleDelete",businessId);

    let parms = {
      url: "adminDeleteBusiness?businessId=" + businessId,
    };
    mutationdelete.mutate(parms);
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-2"
            loading="lazy"
            src={
              row?.profilePic == null
                ? avatar1
                : Image_endpoint + row.profilePic
            }
          />
          {row.fullName}
        </>
      ),
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => row.email,
    },
    {
      name: "Business Name",
      selector: (row) => row.businessDetail.businessName,
      sortable: true,
      cell: (row) => row.businessDetail.businessName,
    },
    {
      name: "Phone No.",
      selector: (row) => row.businessDetail.businessPhoneNumber,
      sortable: true,
      cell: (row) => row.businessDetail.businessPhoneNumber ?? "N/A",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link
            className="btnview"
            to={`/Viewbusiness/${row?.businessDetail?._id}`}
          >
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link
            className="btnedit"
            to={`/Editbusiness/${row?.businessDetail?._id}`}
          >
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link
            className="btndelete"
            onClick={() => handleDelete(row?.businessDetail?._id)}
          >
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];

  // Filter data based on search text
  const filteredData = businessList?.data?.list?.filter(
    (item) =>
      item.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
      // item.phoneNumber.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Business</h5>
              <Button
                className="btn btn-primary px-4 min_width140"
                as={Link}
                to="/Addbusiness"
              >
                Add
              </Button>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Toaster/>
    </React.Fragment>
  );
}
