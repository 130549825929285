import React, { useState } from "react";
import { Modal, Card, Form, Button, Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import { AuthPost, AuthPostdelete, AuthgetApi } from "../axiosConfig/promise";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import subCategorySchema from "../formValidation/subCatgSchema";
import toast, { Toaster } from "react-hot-toast";

export default function Menuitems() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [form, setForm] = useState({});
  const [editForm, setEditForm] = useState({});
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [errors, setErrors] = useState({});

  const { id } = useParams();
  const url = `fetchBusinessSubCategory?categoryId=${id}`;

  const queryClient = useQueryClient();

  const {
    data: subcategorylist,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["subcatglisting", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (subcategory) => {
    setEditForm({ title: subcategory.subCategory });
    setSelectedSubCategoryId(subcategory._id);
    setShow2(true);
  };

  const mutation = useMutation({
    mutationFn: (params) =>
      AuthPost(params.url, {
        subCategory: params.subCategory,
        categoryId: params.categoryId,
        id: params.id,
      }),
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Sub category added successfully!");
        queryClient.invalidateQueries("subcatglisting");
        setForm({});
        setErrors({});
        handleClose();
        handleClose2();

        return;
      }
      toast.error(data?.message);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
    retry: 3,
    retryDelay: 1000,
    mutationKey: "subcatglisting",
    onErrorThrow: true,
  });

  const Updatemutation = useMutation({
    mutationFn: (params) =>
      AuthPost(params.url, {
        subCategory: params.subCategory,
        subCategoryId: params.categoryId,
      }),
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Sub category updated successfully!");
        queryClient.invalidateQueries("subcatglisting");
        setErrors({});
        handleClose();
        handleClose2();
        return;
      }
      toast.error(data?.message);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
    retry: 3,
    retryDelay: 1000,
    mutationKey: "subcatglisting",
    onErrorThrow: true,
  });

  const mutationdelete = useMutation({
    mutationFn: (params) => AuthPostdelete(params.url),
    onSuccess: (data) => {
      // Store the token and fetch user details
      if (data.status) {
        toast.success("Subcategory deleted successfully!");
        queryClient.invalidateQueries("notficationlist"); // Invalidate and refetch admin data if you have a query for it
        setErrors({});
        //   setShow(false)
        handleClose();
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error(" error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "notficationlist", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });
  const validate = (formData) => {
    const { error } = subCategorySchema.validate(formData, {
      abortEarly: false,
    });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validate(form);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const params = {
        url: "createBusinessSubCategory",
        subCategory: form?.title,
        categoryId: id,
      };
      mutation.mutate(params);
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const formErrors = validate(editForm);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const params = {
        url: "UpdateSubCategory",
        subCategory: editForm?.title,
        categoryId: selectedSubCategoryId,
      };
      Updatemutation.mutate(params);
    }
  };

  const handleDelete = (subCategoryId) => {
    const params = {
      url: "deleteSubCategory?subCategoryId=" + subCategoryId,
    };
    mutationdelete.mutate(params);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.subCategory,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YY"),
      sortable: true,
    },
    {
      name: "Actions",
      maxWidth: "150px",
      cell: (row) => (
        <>
          <Link className="btnedit" onClick={() => handleShow2(row)}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete" onClick={() => handleDelete(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];

  const filteredData = subcategorylist?.data?.filter((item) =>
    item.subCategory.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Subcategory</h5>
          <Button
            className="btn btn-primary px-4 min_width140"
            onClick={handleShow}
          >
            Add
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} centered className="itemmodal">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Add Subcategory</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="gx-2">
              <Col lg={12}>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    value={form?.title}
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    type="text"
                    placeholder="Enter Title"
                  />
                  <p style={{ fontSize: 12, color: "red" }}>
                    {errors.title && <span>{errors.title}</span>}
                  </p>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Button onClick={handleClose} className="btn btn-secondary px-4 ">
            Cancel
          </Button>
          <Button onClick={handleSubmit} className="btn btn-primary px-4 mx-2">
            Add
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={handleClose2} centered className="itemmodal">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Edit Subcategory</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="gx-2">
              <Col lg={12}>
                <Form.Group className="mb-3 form-group">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Title"
                    value={editForm?.title}
                    onChange={(e) =>
                      setEditForm({ ...editForm, title: e.target.value })
                    }
                  />
                  <p style={{ fontSize: 12, color: "red" }}>
                    {errors.title && <span>{errors.title}</span>}
                  </p>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Button onClick={handleClose2} className="btn btn-secondary px-4 ">
            Cancel
          </Button>
          <Button
            onClick={handleEditSubmit}
            className="btn btn-primary px-4 mx-2"
          >
            Update
          </Button>
        </Modal.Body>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}
