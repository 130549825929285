import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from 'react-bootstrap';
import logo from "../assets/img/logo.svg";
import { Link } from 'react-router-dom';
import { changePassword } from "../axiosConfig/promise";
import { ThreeCircles } from "react-loader-spinner";

export default function Login() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!password) {
      newErrors.password = 'New password is required';
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = 'Confirm password is required';
    }
    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);
      try {
        const result = await changePassword(password, confirmPassword);
        clearCookies()
        navigate("/")
        console.log('Password changed successfully:', result);
        // Navigate to another page or show success message
        setLoading(false);
      } catch (error) {
        console.error('Error changing password:', error);
        // Show error message
        setLoading(false);
      }
    }
  };
  const clearCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Reset Password</h2>
              <p>Please enter the details below to reset your password.</p>
            </div>
            <Form onSubmit={handleChangePassword}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new password"
                />
                {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
              </Form.Group>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Enter confirm new password"
                />
                {errors.confirmPassword && <div style={{ color: 'red' }}>{errors.confirmPassword}</div>}
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-100 mt-3">
                {loading ? (
                  <ThreeCircles
                    visible={true}
                    height="30"
                    width="30"
                    color="white"
                    ariaLabel="three-circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                ) : (
                  "Reset Password"
                )}
              </Button>
              <p className="formfooter text-center mt-3">Back To <Link to="/">Login</Link></p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link><img src={logo} alt="Logo" /></Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
