import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button ,
} from 'react-bootstrap';
import logo from "../assets/img/logo.svg";
import { Link } from 'react-router-dom';
import { useMutation } from "@tanstack/react-query";
import { AuthPost } from "../axiosConfig/promise";
import toast, { Toaster } from "react-hot-toast";
import forgotpassSchema from "../formValidation/forgotpassSchema";
import { ThreeCircles } from "react-loader-spinner";
import Cookie from "js-cookie"
export default function ForgotPassword() {
  const navigate = useNavigate();
  const [form,setform]=useState({})
  const [errors, setErrors] = useState({});

  const mutation = useMutation({
    mutationFn:(params) => AuthPost(params.url, { email: params.email}),
    onSuccess: (data) => {
      // Store the token and fetch user details
      console.log(data,"is here --- >")
      if(data.status){
        toast.success('OTP has been sent on provided email successfully!')
        Cookie.set("resetToken",data?.data?.hash)
        Cookie.set("email",form?.email)
        setTimeout(() => {
        navigate("/OTP")
        }, 1000);

  return
      }

      toast.success(data?.message)
return
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error('Login error:', error.message);
      toast.error(error.message)

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: 'login', // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });
  
  

  const validate = (formData) => {
    const { error } = forgotpassSchema.validate(formData, { abortEarly: false });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
        errors[item.path[0]] = item.message;
    }
    return errors;
};


const handleSubmit = (e) => {
  e.preventDefault();
  const formErrors = validate(form);
  console.log(formErrors,"formErrors==>")
  setErrors(formErrors);

  if (Object.keys(formErrors).length === 0) {
    let parms = {
      url:"adminForgotPassword",
      email:form?.email,
    }
  mutation.mutate(parms);
    // Submit the form
    // console.log('Form submitted successfully', values);
  }
};


  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Forgot Password</h2>
              <p>Please enter your email address to  receive an otp for password reset.</p>
            </div>
            <Form className="">
              <Form.Group className="mb-4 form-group">
                <Form.Label>Email Address</Form.Label>
                <Form.Control value={form?.email} onChange={(e)=>setform({...form,email:e.target.value})} type="email" placeholder="Enter email address"/>
                <p style={{fontSize:12,color:'red'}}>
                {errors.email && <span>{errors.email}</span>}
                </p>
              </Form.Group>
              <Link>
              <Button 
    className="btn btn-primary w-100 mt-3" 
    onClick={handleSubmit}
  >
    {mutation.isPending ? (
      <ThreeCircles
        visible={true}
        height="30"
        width="100"
        color="white"
        ariaLabel="three-circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    ) : (
      "Proceed"
    )}
  </Button>
           
              </Link>
              <p className="formfooter text-center mt-3">Back To <Link to="/">Login</Link></p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link><img src={logo} alt=""/></Link>
          </div>
        </Col>
      </Row>
      <Toaster />
    </React.Fragment>
  );
}
