import { useMutation, useQueryClient } from '@tanstack/react-query';
import { authFormdataPut } from './promise';
// import { authFormdataPut } from './path-to-your-service';

const useUpdateAdmin = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data) => authFormdataPut(`editAdminProfile`, data.formdata),
        onSuccess: () => {
            queryClient.invalidateQueries('admindetails'); // Invalidate and refetch admin data if you have a query for it
        },
    });
};

export default useUpdateAdmin;
