import React, { useEffect, useState } from "react";
import { Modal, Card, Form, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import avatar1 from "../assets/img/userport.png";
import advertisementimg1 from "../assets/img/advertisementimg1.jpg";
import {
  AuthgetApi,
  authFormdataPost,
  AuthdeleteApi,
  AuthPostdelete,
} from "../axiosConfig/promise";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Image_endpoint, REACT_APP_API_URL } from "../../local";
import toast, { Toaster } from "react-hot-toast";
import bannerschema from "../formValidation/bannerSchema";
import { authInstance } from "../axiosConfig/configuraton";
import { ThreeCircles } from "react-loader-spinner";

export default function Banner() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [editBannerId, setEditBannerId] = useState(null);
  const handleClose = () => {
    setShow(false);
    setform({});
    setpreview(null);
  };
  const handleShow = () => setShow(true);
  const [showimg, setShowimg] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const handleClose2 = () => {
    setShow2(false);
    setform({});
    setpreview(null);
  };
  const handleShowimg = (imgUrl) => {
    console.log("imgUrl", imgUrl);
    setSelectedImg(imgUrl);
    setShowimg(true);
  };
  const handleCloseimg = () => {
    setSelectedImg(null);
    setShowimg(false);
  };
  const handleShow2 = (banner) => {
    setEditBannerId(banner._id);
    setform({
      businessId: banner.businessId?._id,
      message: banner.message,
      bannerImg: banner.bannerImg,
    });
    setpreview(Image_endpoint + banner.bannerImg);
    setShow2(true);
  };
  const [searchText, setSearchText] = useState("");
  const [form, setform] = useState({
    bannerImg: null, // Initialize with null if no image is selected initially
    businessId: "",
    message: "",
  });
  const [preview, setpreview] = useState(null);
  const queryClient = useQueryClient();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log(form, "form===>");
  }, [form]);

  let url = `adminGetBannerList`;

  const {
    data: bannerlist,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["bannerlist", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });

  let urls = `adminBusinessList`;

  const {
    data: businessList,
    error: businessError,
    isLoading: errorIsloading,
  } = useQuery({
    queryKey: ["businesslist", urls],
    queryFn: () => AuthgetApi(urls),
    staleTime: Infinity,
  });

  const handleImage = (event) => {
    event.preventDefault();
    console.log("checking image selected or not");
    let file = event.target.files[0];
    if (file) {
      setpreview(URL.createObjectURL(file)); // Set the preview URL
      setform((prev) => ({
        ...prev,
        bannerImg: file,
      }));
    }
    return;
  };

  const columns = [
    {
      name: "Image",
      selector: (row) => row.bannerImg,
      sortable: true,
      cell: (row) => (
        <>
          <img
            loading="lazy"
            className="table_img rounded-circle img-thumbnail me-2"
            src={
              row?.bannerImg == null ? avatar1 : Image_endpoint + row.bannerImg
            }
          />
        </>
      ),
    },
    {
      name: "Business",
      selector: (row) => row.businessId?.businessName,
      sortable: true,
    },
    {
      name: "Actions",
      minWidth: "150px",
      cell: (row) => (
        <>
          <Link className="btnedit" onClick={() => handleShow2(row)}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete" onClick={() => handleDelete(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];

  const convertToFormData = (form) => {
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    return formData;
  };

  // Filter data based on search text
  const filteredData = bannerlist?.data?.list?.filter(
    (item) =>
      item.businessId?.businessName
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.message?.toLowerCase().includes(searchText.toLowerCase())
  );

  const mutation = useMutation({
    mutationFn: authFormdataPost,
    onSuccess: (data) => {
      if (data.status) {
        toast.success(data?.message);
        queryClient.invalidateQueries("bannerlist");
        setErrors({});
        setform({});
        handleClose();
        handleClose2();
        return;
      }
    },
    onError: (error) => {
      console.error(" error:", error.message);
      toast.error(error.message);
    },
    retry: 3,
    retryDelay: 1000,
    mutationKey: "categorylist",
    onErrorThrow: true,
  });

  const validate = (formData) => {
    const { error } = bannerschema.validate(formData, { abortEarly: false });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const mutationdelete = useMutation({
    mutationFn: (params) => AuthPostdelete(params.url),
    onSuccess: (data) => {
      // Store the token and fetch user details
      if (data.status) {
        toast.success("Banner deleted successfully!");
        queryClient.invalidateQueries("notficationlist"); // Invalidate and refetch admin data if you have a query for it
        setErrors({});
        //   setShow(false)
        handleClose();
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error(" error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "notficationlist", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validate(form);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const parms = {
        url: "bannerSubmission",
        formdata: convertToFormData(form),
      };
      console.log(parms, "parms--->");
      mutation.mutate(parms);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const formErrors = validate(form);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      form.bannerId = editBannerId;
      const parms = {
        url: `updateBanner`,
        formdata: convertToFormData(form),
      };
      console.log(parms, "parms--->");
      mutation.mutate(parms);
    }
  };

  const handleDelete = (bannerId) => {
    let parms = {
      url: "deleteBanner?bannerId=" + bannerId,
    };
    mutationdelete.mutate(parms);
  };

  const removeImage = (e) => {
    e.preventDefault();
    setpreview(""); // Clear the preview URL
    setform((prev) => ({
      ...prev,
      bannerImg: null, // Set the bannerImg to null
    }));
    document.getElementById("categoryimg").value = null;

    return;
  };

  const removeImageA = (e, val, key) => {
    e.preventDefault();
    const isBlob = val.startsWith("blob:");
    if (isBlob) {
      // Remove from local
      setpreview(""); // Clear the preview URL
      setform((prev) => ({
        ...prev,
        bannerImg: "",
      }));
      // document.getElementById('categoryimg').value = null;
    } else {
      // Call remove API
      authInstance
        .post(
          `${REACT_APP_API_URL}/deleteBusinessCertificatePic`,
          {
            fileName: val.split("/").pop(), // Extract the file name from the URL
            businessId: editBannerId, // Replace with the actual business ID
            type: key,
          },
          {
            headers: {
              Authorization: `Bearer YOUR_BEARER_TOKEN`, // Replace with your actual Bearer Token
            },
          }
        )
        .then((response) => {
          console.log("API Response:", response);
          // Remove from local after successful API call
          queryClient.invalidateQueries("bannerlist");
          setpreview(""); // Clear the preview URL
          setform((prev) => ({
            ...prev,
            bannerImg: "",
          }));
          // document.getElementById('categoryimg').value = null;
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Banners</h5>
          <Button
            className="btn btn-primary px-4 min_width140"
            onClick={handleShow}
          >
            Add
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Add Banner</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Business</Form.Label>
              <Form.Select
                value={form?.businessId}
                onChange={(e) =>
                  setform({ ...form, businessId: e.target.value })
                }
                aria-label="Select Business"
              >
                <option>Select Business</option>
                {businessList?.data?.list?.map((val, ind) => {
                  return (
                    <option key={ind} value={val?.businessDetail?._id}>
                      {val?.fullName}
                    </option>
                  );
                })}
              </Form.Select>
              <p style={{ fontSize: 12, color: "red" }}>
                {errors.businessId && <span>{errors.businessId}</span>}
              </p>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Message</Form.Label>
              <Form.Control
                value={form?.message}
                onChange={(e) => setform({ ...form, message: e.target.value })}
                id="message"
                placeholder="message"
                name="message"
                as="textarea"
              />
              <p style={{ fontSize: 12, color: "red" }}>
                {errors.message && <span>{errors.message}</span>}
              </p>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Image</Form.Label>
              <Form.Control
                onChange={handleImage}
                id="categoryimg"
                name="categoryimg"
                className="displaynone"
                accept="image/*"
                type="file"
              />
              <Form.Label className="fileupload" htmlFor="categoryimg">
                <Icon className="uplaodicon" icon="ion:camera-outline"></Icon>
                <h3>Drag & Drop Here</h3>
              </Form.Label>
            </Form.Group>
            {preview && (
              <>
                <img
                  src={preview}
                  alt="img"
                  style={{
                    width: "80px",
                    height: "80px",
                    margin: 10,
                    borderRadius: 10,
                  }}
                  onClick={() => {
                    handleShowimg(`${preview}`);
                  }}
                />
                <span
                  onClick={removeImage}
                  style={{
                    color: "white",
                    position: "absolute",
                    marginLeft: "-20px",
                    border: "0.4px solid",
                    borderRadius: "180px",
                    background: "darkred",
                    fontWeight: "bold",
                    display: "inline-flex", // Ensures the icon is centered
                    alignItems: "center",
                    justifyContent: "center",
                    width: "20px", // Ensure the size of the container
                    height: "20px", // Ensure the size of the container
                    cursor: "pointer",
                  }}
                >
                  <Icon icon="tabler:x" />
                </span>
              </>
            )}
          </Form>
          {/* <Button onClick={handleSubmit} className="btn btn-primary px-4 w-100">
                        Add
                    </Button> */}
          <Button className="btn btn-primary px-4 w-100" onClick={handleSubmit}>
            {" "}
            {mutation.isPending ? (
              <ThreeCircles
                visible={true}
                height="30"
                width="100"
                color="white"
                ariaLabel="three-circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Add"
            )}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Edit Banner</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Business</Form.Label>
              <Form.Select
                aria-label="Select Business"
                value={form?.businessId}
                onChange={(e) =>
                  setform({ ...form, businessId: e.target.value })
                }
              >
                <option>Select Business</option>
                {businessList?.data?.list?.map((val, ind) => {
                  return (
                    <option key={ind} value={val?.businessDetail?._id}>
                      {val?.fullName}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Message</Form.Label>
              <Form.Control
                value={form?.message}
                onChange={(e) => setform({ ...form, message: e.target.value })}
                id="message"
                placeholder="message"
                name="message"
                as="textarea"
              />
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Image</Form.Label>
              <Form.Control
                onChange={handleImage}
                id="categoryimg"
                name="categoryimg"
                className="displaynone"
                type="file"
              />
              <Form.Label className="fileupload" htmlFor="categoryimg">
                <Icon className="uplaodicon" icon="ion:camera-outline"></Icon>
                <h3>Drag & Drop Here</h3>
              </Form.Label>

              {preview &&
                preview !== "https://adaapbackendd.s3.amazonaws.com/null" && (
                  <div className="formimg">
                    <img
                      src={preview}
                      alt="img"
                      style={{
                        width: "80px",
                        height: "80px",
                        margin: 10,
                        borderRadius: 10,
                      }}
                      onClick={() => {
                        handleShowimg(`${preview}`);
                      }}
                    />
                    <Icon
                      className="closebtn"
                      icon="material-symbols:close"
                      onClick={(e) => removeImageA(e, preview, "bannerImg")}
                    ></Icon>
                  </div>
                )}
            </Form.Group>
          </Form>
          {/* <Button onClick={handleUpdate} className="btn btn-primary px-4 w-100">
                        Save
                    </Button> */}
          <Button className="btn btn-primary px-4 w-100" onClick={handleUpdate}>
            {" "}
            {mutation.isPending ? (
              <ThreeCircles
                visible={true}
                height="30"
                width="100"
                color="white"
                ariaLabel="three-circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Update"
            )}
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        className="modalimg_div"
        show={showimg}
        onHide={handleClose}
        centered
      >
        <button className="remove-icon" onClick={handleCloseimg}>
          &times;
        </button>
        <Modal.Body>
          <img src={selectedImg} alt="" />
        </Modal.Body>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}
