// validationSchema.js
import Joi, { allow, any } from 'joi';


// Subcategory validation schema
const notificationSchema = Joi.object({
    message: Joi.string()
    .trim()
      .min(8)
      .required()
      .messages({
        'string.empty':"Message is required",
        'string.min': 'Message must be at least 8 characters long',
        'any.required': 'Message is required',
      }),
 });
  

export default notificationSchema;
