import React, { useState, Component } from "react";
import { Card, Button, Modal, Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { AuthPost, AuthgetApi, authFormdataPost } from "../axiosConfig/promise";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import notificationSchema from "../formValidation/notificationSchema";
import toast, { Toaster } from "react-hot-toast";
import { ThreeCircles } from "react-loader-spinner";
export default function Notifications() {
  const [form, setform] = useState({});
  const url = `getAdminNotification`;
  // notificationSchema
  const {
    data: notficationlist,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["notficationlist", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });

  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (params) => AuthPost(params.url, { message: params.message }),
    onSuccess: (data) => {
      // Store the token and fetch user details
      if (data.status) {
        toast.success("Notification sent successfully!");
        setform({});
        queryClient.invalidateQueries("notficationlist"); // Invalidate and refetch admin data if you have a query for it
        setErrors({});
        //   setShow(false)
        handleClose();
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error(" error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "notficationlist", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });
  const mutationDelete = useMutation({
    mutationFn: (params) => AuthPost(params.url),
    onSuccess: (data) => {
      // Store the token and fetch user details
      if (data.status) {
        toast.success("Notification cleared successfully!");
        
        queryClient.invalidateQueries("notficationlist"); // Invalidate and refetch admin data if you have a query for it
        setErrors({});
        //   setShow(false)
        // handleClose();
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error(" error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "notficationlist", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });

  const validate = () => {
    const { error } = notificationSchema.validate(form, { abortEarly: false });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleNotificationSend = (e) => {
    e.preventDefault();
    const formErrors = validate();
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      //   let parms = {
      //     url:"createBusinessCategory",
      //     category:form?.title,
      //   }
      // mutation.mutate(parms);
      let parms = {
        url: "sendNotification",
        message: form?.message,
      };
      mutation.mutate(parms);
      // Submit the form
      // console.log('Form submitted successfully', values);
    }
  };
  const handleNotificationClear = (e) => {
    console.log("working");
    e.preventDefault();
    const formErrors = validate();
    setErrors(formErrors);
    // if (Object.keys(formErrors).length === 0) {
    console.log("working1");
    //   let parms = {
    //     url:"createBusinessCategory",
    //     category:form?.title,
    //   }
    // mutation.mutate(parms);
    let parms = {
      url: "clearAdminNotification",
      // message: form?.message,
    };
    mutationDelete.mutate(parms);
    // Submit the form
    // console.log('Form submitted successfully', values);
    // }
  };

  const Data = [
    {
      id: 1,
      name: "Notification",
      description:
        "A new restaurant in the market “Pacific Fine Dine”. Check their page and find amazing things about them",
      time: "10 min ago",
    },
    {
      id: 2,
      name: "Notification",
      description:
        "Your plan is expiring soon. Please renew to continue enjoying our services.",
      time: "2 hours ago",
    },
    {
      id: 3,
      name: "Notification",
      description:
        "Jason Carter has provided a rating and review for you. You can view it and respond accordingly.",
      time: "1 day ago",
    },
    {
      id: 4,
      name: "Notification",
      description:
        "A new restaurant in the market “Pacific Fine Dine”. Check their page and find amazing things about them",
      time: "2 days ago",
    },
    {
      id: 4,
      name: "Notification",
      description:
        "Your plan is expiring soon. Please renew to continue enjoying our services.",
      time: "4 days ago",
    },
  ];

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Notifications</h5>
          <div className="">
            <Button
              className="btn btn-primary  min_width140"
              onClick={handleShow}
            >
              Add
            </Button>
            {notficationlist?.data?.list &&
          notficationlist.data.list.length > 0?
            <Button
              className="btn btn-primary  min_width140 ms-3"
              onClick={handleNotificationClear}
            >
              Clear Notification
            </Button>:""}
          </div>
        </Card.Header>
        <Card.Body>
          {notficationlist?.data?.list &&
          notficationlist.data.list.length > 0 ? (
            notficationlist.data.list.map((item, index) => (
              <div key={index} className="notificationlist mb-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h2>{item?.title}</h2>
                  <span>
                    {moment(item.createdAt).format("DD-MM-YYYY HH:mm A")}
                  </span>
                </div>
                <p>{item.message}</p>
              </div>
            ))
          ) : (
            <p>No data found</p>
          )}
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Send Notification</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3 form-group">
                            <Form.Label>Search User</Form.Label>
                            <Form.Control type="text" placeholder="Search User" />
                        </Form.Group> */}
            <Form.Group className="mb-3 form-group">
              <Form.Label>Message</Form.Label>
              <Form.Control
                value={form?.message}
                onChange={(e) => setform({ ...form, message: e.target.value })}
                as="textarea"
                rows={4}
                placeholder="Enter Message"
              />
              <p style={{ fontSize: 12, color: "red" }}>
                {errors.message && <span>{errors.message}</span>}
              </p>
            </Form.Group>
          </Form>
          <Button
            onClick={handleClose}
            className="btn btn-secondary px-4 min_width110"
          >
            Cancel
          </Button>
          <Button
            onClick={handleNotificationSend}
            className="btn btn-primary px-4 mx-2 min_width110"
          >
            {" "}
                  {mutation.isPending ? (
                    <ThreeCircles
                      visible={true}
                      height="30"
                      width="100"
                      color="white"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Send"
                  )}
            {/* Send */}
          </Button>
        </Modal.Body>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}
