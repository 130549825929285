import React, { useEffect, useState } from "react";
import { Card, Table, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Logo from "../assets/img/businesslogo.jpg";
import businessimg1 from "../assets/img/businessimg1.jpg";
import businessimg2 from "../assets/img/businessimg2.jpg";
import certificateimg from "../assets/img/certificateimg.jpg";
import { useQuery } from "@tanstack/react-query";
import { AuthgetApi } from "../axiosConfig/promise";
import { GOOGLE_API_KEY, Image_endpoint } from "../../local";
export default function Viewbusiness() {
  const { id } = useParams();
  const [locationName, setLocationName] = useState("N/A");
  console.log(id, "id========>>>>>>>>");
  const [show, setShow] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  let url = "adminViewBusiness?businessId=" + id;
  const {
    data: userinfo,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["businesslist", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });
  useEffect(() => {
    if (userinfo) {
      console.log(userinfo, "userinfo<<<<<>>>>>>");
  
      // Safeguard against empty or invalid JSON in locationName
      try {
        let locationName = userinfo?.data.list[0]?.locationName;
  
        if (locationName) {
          console.log("locationName");
          
          let val = JSON.parse(locationName); // Parse only if it's valid
          setLocationName(val?.label ?? "N/A");
        } else {
          setLocationName(userinfo?.data.list[0]?.businessAddress);
        }
      } catch (error) {
        console.error("Error parsing locationName:", error);
        setLocationName("N/A"); // Handle the error case
      }
    }
  }, [userinfo]);
  // useEffect(()=>{
  //   if(userinfo){
  //     getLocationName(userinfo?.data?.list[0]?.businessLocation?.coordinates[0],userinfo?.data?.list[0]?.businessLocation?.coordinates[1])
  //   }
  // },[userinfo])
  const convertTo24Hour = (timeString) => {
    return timeString
      ? new Date(timeString).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false // 24-hour format
        })
      : "";
  };
  function convertTo12Hour(time) {
    const [hours, minutes] = time.split(":");
    const hoursInt = parseInt(hours, 10);
    const period = hoursInt >= 12 ? "PM" : "AM";
    const hours12 = hoursInt % 12 || 12; // Convert 0 to 12 for midnight
    return `${hours12}:${minutes} ${period}`;
  }

  //  const getLocationName = async (lat, lng) => {
  //         const response = await fetch(
  //           `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
  //         );
  //         const data = await response.json();
  //         if (data.results && data.results[0]) {
  //           console.log(data.results[0],"data.results[0]?.locationName.========>")
  //           setLocationName(JSON.parse(data.results[0]?.locationName));
  //           getFormattedAddressFromPlusCode(data.results[0].formatted_address)
  //         } else {
  //           setLocationName('Unknown location');
  //         }
  //       };

  const getFormattedAddressFromPlusCode = async (plusCode) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${plusCode}&key=${GOOGLE_API_KEY}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch address");
      }

      const data = await response.json();

      if (data.results.length > 0) {
        const formattedAddress = data.results[0].formatted_address;
        console.log("Formatted Address:", formattedAddress);
        return formattedAddress;
      } else {
        console.log("No address found for Plus Code:", plusCode);
        return "Unknown address";
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      return "Error fetching address";
    }
  };
  const handleShow = (imgUrl) => {
    console.log(imgUrl);
    setSelectedImg(imgUrl);
    setShow(true);
  };
  const handleClose = () => {
    setSelectedImg(null);
    setShow(false);
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Business Details</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Business"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Table responsive>
            <tbody>
              <tr>
                <td>
                  <b>Name</b>
                </td>
                <td>{userinfo?.data?.list[0]?.fullName ?? "N/A"}</td>
              </tr>
              <tr>
                <td>
                  <b>Email</b>
                </td>
                <td>{userinfo?.data?.list[0]?.email ?? "N/A"}</td>
              </tr>
              <tr>
                <td>
                  <b>Phone No.</b>
                </td>
                <td>
                  {userinfo?.data?.list[0]?.phoneNumber ??
                    userinfo?.data?.list[0]?.businessPhoneNumber}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Business Name</b>
                </td>
                <td>{userinfo?.data?.list[0]?.businessName ?? "N/A"}</td>
              </tr>
              <tr>
                <td>
                  <b>Location</b>
                </td>
                <td>{locationName}</td>
              </tr>
              <tr>
                <td>
                  <b>Category</b>
                </td>
                <td>
                  {userinfo?.data?.list[0]?.businesscategories[0]?.label ??
                    "N/A"}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Subcategory</b>
                </td>
                <td style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
                  {userinfo?.data?.list[0]?.businesssubcategories?.map(
                    (val, ind) => (
                      <p
                        style={{
                          padding: 5,
                          borderRadius: 10,
                          backgroundColor: "blue",
                          color: "white",
                          fontWeight: "bold",
                          paddingLeft: 15,
                          paddingRight: 15,
                        }}
                      >
                        {val?.label}
                      </p>
                    )
                  )}
                </td>
              </tr>
              {/* <tr>
                <td><b>Likes</b></td>
                <td>{userinfo?.data?.list[0]?.Likes}</td>
              </tr> */}
              {/* <tr>
                <td><b>Plan</b></td>
                <td>{userinfo?.data?.list[0]?.Plan}</td>
              </tr> */}
              <tr>
                <td>
                  <b>Business Description</b>
                </td>
                <td>{userinfo?.data?.list[0]?.businessDescription ?? "N/A"}</td>
              </tr>
              <tr>
                <td>
                  <b>Is open 24*7</b>
                </td>
                <td>
                  {userinfo?.data?.list[0]?.isOpen24Hours ? "YES" : "NO"}
                </td>
              </tr>
              <tr>
                {/* businessCertificate */}
                <td>
                  <b>Certificate</b>
                </td>
                <td>
                  {userinfo?.data?.list[0]?.businessCertificate?.map(
                    (val, ind) => (
                      <img
                        key={ind}
                        className="dtlimg img-thumbnail"
                        onClick={() => {
                          // console.log(" working ");
                          handleShow(`${Image_endpoint}${val}`);
                        }}
                        src={Image_endpoint + val}
                      />
                    )
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Logo</b>
                </td>
                <td>
                  <img
                    className="dtlimg img-thumbnail"
                    src={
                      userinfo?.data?.list[0]?.businessLogo
                        ? Image_endpoint + userinfo?.data?.list[0]?.businessLogo
                        : userinfo?.logo
                    }
                    onClick={() => {
                      // console.log(" working ");
                      handleShow(
                        `${Image_endpoint}${userinfo?.data?.list[0]?.businessLogo?userinfo?.data?.list[0]?.businessLogo: userinfo?.logo}`
                      );
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Business Photos</b>
                </td>
                <td>
                  {userinfo?.data?.list[0]?.businessPic?.map((val, ind) => (
                    <img
                      key={ind}
                      className="dtlimg img-thumbnail"
                      src={Image_endpoint + val}
                      onClick={() => {
                        // console.log(" working ");
                        handleShow(`${Image_endpoint}${val}`);
                      }}
                    />
                  ))}
                </td>
              </tr>
              {!userinfo?.data?.list[0]?.isOpen24Hours ? (
                <tr>
                  <td colSpan={2}>
                    <b>Opening Hours</b>
                    <Table className="openinghourtable">
                      <tbody>
                        {/* {
                        userinfo?.data?.list[0]?.times?.map((val,ind)=>(
                          <tr key={ind}>
                          <td>Monday</td>
                          <td>09:00 am - 10:00 pm</td>
                        </tr>
                        ))
                      }
                      */}
                        {userinfo?.data?.list?.[0]?.times &&
                          Object.keys(userinfo.data.list[0].times).map(
                            (val, ind) => (
                              <tr key={ind}>
                                <td>{val}</td>
                                <td>
                                  {convertTo24Hour(
                                    userinfo.data.list[0].times[val]?.from
                                  )}{" "}
                                  -
                                  {convertTo24Hour(
                                    userinfo.data.list[0].times[val]?.to
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal className="modalimg_div" show={show} onHide={handleClose} centered>
        <button className="remove-icon" onClick={handleClose}>
          &times;
        </button>
        <Modal.Body>
          <img src={selectedImg} alt="" />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
