const Joi = require('joi');

const schemapassChange = Joi.object({
  currentPassword: Joi.string()
    .min(8) // Example minimum length constraint
    .required()
    .label('Current Password')
    .messages({
      'string.min': 'Current Password must be at least 8 characters long',
      'any.required': 'Current Password is required',
      'string.empty': 'Current Password cannot be empty',
    }),
  newPassword: Joi.string()
    .min(8) // Example minimum length constraint
    .required()
    .label('New Password')
    .messages({
      'string.min': 'New Password must be at least 8 characters long',
      'any.required': 'New Password is required',
      'string.empty': 'New Password cannot be empty',
    }),
  confirmPassword: Joi.string()
    .valid(Joi.ref('newPassword')) // Ensure it matches newPassword
    .required()
    .label('Confirm Password')
    .messages({
      'any.only': 'Confirm Password does not match New Password',
      'any.required': 'Confirm Password is required',
      'string.empty': 'Confirm Password cannot be empty',
    }),
});

module.exports = schemapassChange;
