// validationSchema.js
import Joi from 'joi';

const validationSchema = Joi.object({
    fullName: Joi.string()
    .min(2)
    .required()
    .messages({
      'string.min': 'Name must be at least 2 characters long',
      'any.required': 'Name is required',
    }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.email': 'Please enter a valid email address',
      'any.required': 'Email is required',
    }),
  password: Joi.string()
    .min(6)
    .required()
    .messages({
      'string.min': 'Password must be at least 6 characters long',
      'any.required': 'Password is required',
    }),
  phoneNumber: Joi.string()
    .pattern(/^[0-9]{5,15}$/)
    .required()
    .messages({
      'string.pattern.base': 'Phone number must be between 5 and 15 digits',
      'any.required': 'Phone number is required',
    }),
    profilePic:Joi.any().allow()
});

export default validationSchema;
