import React, { useState } from "react";
import {
    Modal,
    Card,
    Form,
    Button,
} from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default function Allpages() {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [categoryname, setCategoryname] = useState('Privacy Policy');
    const [searchText, setSearchText] = useState('');  
    const columns = [
        {
          name: 'Name',
          selector: row => row.name,
          sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date, 
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Link className="btnedit" onClick={handleShow2}>
                        <Icon icon="tabler:pencil"></Icon>
                    </Link>
                    <Link className="btndelete">
                        <Icon icon="tabler:trash"></Icon>
                    </Link>
                </>
            ),
        },
    ];
    const data = [
        { 
            id: 1, 
            name: 'Privacy Policy', 
            date: '15 April 2024',  
        },
    ]
    // Filter data based on search text
    const filteredData = data.filter(item =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <React.Fragment>
            <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5>All Pages</h5>
                    <Button className="btn btn-primary px-4 min_width140" onClick={handleShow}>Add</Button>
                </Card.Header>
                <Card.Body>
                    <div className="text-end mb-3">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="searchfield"
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                        />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        responsive
                        className="custom-table"
                    />
                </Card.Body>
            </Card>
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h2 className="modalhead">Add Page</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3 form-group">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter Title" />
                        </Form.Group>
                        <Form.Group className="mb-3 form-group">
                            <Form.Label>Description</Form.Label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data="<p></p>"
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event ) => {
                                    console.log( event );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        </Form.Group>
                    </Form>
                    <Button className="btn btn-primary px-4 w-100">
                        Add
                    </Button>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={show2} onHide={handleClose2} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h2 className="modalhead">Edit Page</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3 form-group">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter Title" value={categoryname} onChange={(e) => setCategoryname(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3 form-group">
                            <Form.Label>Description</Form.Label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data="<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>"
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event ) => {
                                    console.log( event );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                            />
                        </Form.Group>
                    </Form>
                    <Button className="btn btn-primary px-4 w-100">
                        Update
                    </Button>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}