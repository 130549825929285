import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import Avatar from "../assets/img/default.jpg";
import Select, { components } from "react-select";
import GoogleMapReact from "google-map-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  AuthgetApi,
  authFormdataPost,
  authFormdataPut,
} from "../axiosConfig/promise";
import { GOOGLE_API_KEY, Image_endpoint, REACT_APP_API_URL } from "../../local";
import businessValidationSchema from "../formValidation/editBusinessSchema";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { ThreeCircles } from "react-loader-spinner";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import { authInstance } from "../axiosConfig/configuraton";
const subcategory = [
  { value: "Bakery", label: "Bakery", isFixed: false },
  { value: "Sweets", label: "Sweets", isFixed: false },
  { value: "Candy", label: "Candy", isFixed: false },
  { value: "Snacks", label: "Snacks", isFixed: false },
  { value: "Food", label: "Food", isFixed: false },
];
const orderOptions = (values) => {
  if (values.length > 0)
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
};

const AnyReactComponent = ({ lat, lng }) => (
  <div>
    <Icon fontSize={24} style={{ cursor: "pointer" }} icon="ion:location" />
  </div>
);

export default function Editbusiness() {
  const [form, setform] = useState({ businessCategory: null });
  const [fixedValue, setFixedValue] = useState("");
  const [show, setShow] = useState(false);
  const [showimg, setShowimg] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowimg = (imgUrl) => {
    console.log("imgUrl",imgUrl);
    setSelectedImg(imgUrl);
    setShowimg(true);
  };
  const handleCloseimg = () => {
    setSelectedImg(null);
    setShowimg(false);
  };
  const [selectedImg, setSelectedImg] = useState(null);
  const [subcategorylistArr, setsubcatglist] = useState([]);
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({}); // state for validation errors
  const fixedOnChange = (value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = subcategory.filter((v) => v.isFixed);
        break;
      default:
        break;
    }

    value = orderOptions(value);
    setFixedValue(value);
  };
  const [locationName, setLocationName] = useState(null);

  const [currentLat, setlat] = useState("10.99835602");
  const [currentLong, setlong] = useState("77.01502627");
  const [categoryId, setcategoryId] = useState(null);
  const navigate = useNavigate();
  const { businessCategory } = form;
  const [times, setBusinessHours] = useState([]);

  const { id } = useParams();
  const urls = `adminViewBusiness?businessId=${id}`;
  const {
    data: businessDetails,
    error: businessError,
    isLoading: businessLoading,
    refetch,
  } = useQuery({
    queryKey: ["businesslist", urls],
    queryFn: () => AuthgetApi(urls),
    staleTime: Infinity,
  });
  useEffect(() => {
    if (businessDetails) {
      const { businessSubCategory, businessCategory } =
        businessDetails?.data?.list[0];
      const formattedData = formatBusinessData(businessDetails?.data?.list[0]);
      setform(formattedData);
      let val = "N/A";
      if (businessDetails?.data?.list[0]?.locationName) {
        val = JSON.parse(businessDetails?.data?.list[0]?.locationName);
      }
      console.log(val, "val");
      setLocationName(val);
    }
  }, [businessDetails]);

  const formatBusinessData = (apiResponse) => {
    const {
      _id,
      userId,
      businessLogo,
      businessAddress,
      businessName,
      businessPhoneNumber,
      countryCode,
      businessDescription,
      businessCertificate,
      businessCategory,
      businesssubcategories,
      businessHour,
      businessPic,
      email,
      fullName,
      businessLocation,
      isOpen24Hours,
      times,
      accountStatus,
      whatsappLink,
      telegramLink,
      instagramLink,
      profileStep,
    } = apiResponse;
    if (times) setBusinessHours(convertTimesObjectToArray(times));
    setform({ ...form, businessCategory: businessCategory });
    setFixedValue(businesssubcategories);

    // Transforming the times object into the required format
    let transformedTimes;
    if (times) {
      transformedTimes = Object.keys(times).map((day) => ({
        day: day.charAt(0).toUpperCase() + day.slice(1),
        ...times[day],
      }));
    }

    // Forming the formatted data object
    // setpreviewImages
    const businessCertificateArr = businessCertificate.map(
      (cert) => Image_endpoint + cert
    );
    const businessPicArr = businessPic.map((cert) => Image_endpoint + cert);
    const businessLogoString = Image_endpoint + businessLogo;

    setpreviewImages({
      ...previewImage,
      businessLogo: businessLogoString,
      businessCertificate: businessCertificateArr,
      businessPic: businessPicArr,
    });
    const formattedData = {
      _id,
      userId,
      businessLogo,
      // businessAddress,
      businessName,
      businessPhoneNumber,
      countryCode,
      businessDescription,
      businessCertificate,
      businessCategory,
      businessSubCategory: businesssubcategories,
      businessHour,
      businessPic,
      email,
      fullName,
      businessLocation: {
        type: businessLocation.type,
        coordinates: businessLocation.coordinates,
      },
      isOpen24Hours,
      times: transformedTimes,
      accountStatus,
      whatsappLink,
      telegramLink,
      instagramLink,
      profileStep,
    };

    return formattedData;
  };
  // useEffect(()=>{
  //     setFixedValue(form?.businesssubcategories)
  //     if(form?.times){
  //         console.log(convertTimesObjectToArray(form?.times),"convertTimesObjectToArray(form?.times)===>")
  //         setBusinessHours(convertTimesObjectToArray(form?.times))
  //     }
  //     console.log(form,"checking form here --->")
  // },[form])

  const convertTimesObjectToArray = (timesObject) => {
    return Object.keys(timesObject).map((day) => {
      // const [fromHours, fromMinutes] = timesObject[day]?.from?.split(":")?.map(Number);
      let fromTime = timesObject[day]?.from;
      let fromHours, fromMinutes;
      fromTime = new Date(fromTime).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      if (fromTime) {
        const timeParts = fromTime.split(":").map(Number);
        console.log("timeParts", timeParts, fromTime);
        if (timeParts.length === 2) {
          [fromHours, fromMinutes] = timeParts;
        } else {
          // Handle the case where timeParts does not have exactly two elements
          fromHours = 0; // or some default value
          fromMinutes = 0; // or some default value
        }
      } else {
        // Handle the case where fromTime is undefined
        fromHours = 0; // or some default value
        fromMinutes = 0; // or some default value
      }

      const fromDate = new Date();
      fromDate.setUTCHours(fromHours, fromMinutes, 0, 0);
      let fromUtcString;
      if (fromDate != "Invalid Date") {
        fromUtcString = fromDate?.toISOString().substring(11, 16);
      }

      let toHours, toMinutes;
      const toTimeString = timesObject[day]?.to;

      if (toTimeString && typeof toTimeString === "string") {
        [toHours, toMinutes] = toTimeString.split(":").map(Number);
      } else {
        console.error("Invalid 'to' time value");
        // Handle the error or set default values
        toHours = null;
        toMinutes = null;
      }

      const toDate = new Date();
      toDate.setUTCHours(toHours, toMinutes, 0, 0);

      let toUtcString = "";

      if (fromDate && !isNaN(fromDate.getTime())) {
        if (toDate && !isNaN(toDate.getTime())) {
          toUtcString = toDate.toISOString().substring(11, 16);
        } else {
          console.error("Invalid toDate");
        }
      } else {
        console.error("Invalid fromDate");
      }

      return {
        day,
        openingHour: timesObject[day]?.from,
        closingHour: timesObject[day]?.to,
      };
    });
  };
  // const convertTimesObjectToArray = (timesObject) => {
  //   console.log(timesObject,"timesObject===>")
  //     return Object.keys(timesObject).map(day => {
  //         const openingHour = timesObject[day].from
  //             ? moment(timesObject[day].from).format('HH:mm')
  //             : "";
  //         const closingHour = timesObject[day].to
  //             ? moment(timesObject[day].to).format('HH:mm')
  //             : "";

  //         return {
  //             day,
  //             openingHour,
  //             closingHour
  //         };
  //     });
  // };

  //   businessSubCategory
  const url = `fetchBusinessCategory`;
  const {
    data: categorylist,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["categorylist", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });

  const [previewImage, setpreviewImages] = useState({});
  const urlSub = `fetchBusinessSubCategory?categoryId=${businessCategory}`;

  const {
    data: subcategorylist,
    error: suberror,
    isLoading: subloading,
  } = useQuery({
    queryKey: ["subcatglisting", urlSub],
    queryFn: () => AuthgetApi(urlSub),
    staleTime: Infinity,
    enabled: !!businessCategory, // Converts to boolean to ensure the query runs only if businessCategory is not null
  });

  const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const convertToFormData = (form) => {
    const formData = new FormData();

    for (const key in form) {
      if (Array.isArray(form[key])) {
        form[key].forEach((val) => {
          if (val instanceof File || val instanceof Blob) {
            formData.append(key, val);
          } else if (typeof val === "object" && val !== null) {
            formData.append(key, JSON.stringify(val));
          } else {
            formData.append(key, val);
          }
        });
      } else if (form[key] instanceof File || form[key] instanceof Blob) {
        formData.append(key, form[key]);
      } else if (typeof form[key] === "object" && form[key] !== null) {
        formData.append(key, JSON.stringify(form[key]));
      } else {
        formData.append(key, form[key]);
      }
    }

    return formData;
  };

  const mutation = useMutation({
    mutationFn: authFormdataPost,
    onSuccess: (data) => {
      // Store the token and fetch user details
      if (data.status) {
        refetch();
        // const [form,setform]=useState({businessCategory:""})
        setform({ businessCategory: "" });
        queryClient.invalidateQueries("businesslist"); // Invalidate and refetch admin data if you have a query for it

        toast.success("Business edit successfully!");
        navigate(-1);
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      toast.error(error.message);
      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "businesslisting", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });

  const handleCheckboxChange = (day) => {
    setBusinessHours((prevState) => {
      const dayIndex = prevState.findIndex((item) => item.day === day);
      if (dayIndex > -1) {
        // If day is already in the array, remove it
        return prevState.filter((item) => item.day !== day);
      } else {
        // If day is not in the array, add it with default times
        return [...prevState, { day, openingHour: "", closingHour: "" }];
      }
    });
  };

  const handleTimeChange = (day, timeType, value) => {
    console.log("value", value);
    // const today = new Date(); // Get the current date

    // Split the time value into hours and minutes
    const today = new Date(); // Get the current date

    // Split the time value into hours and minutes
    const [hours, minutes] = value.split(":").map(Number);

    // Set the time in the today date
    today.setHours(hours);
    today.setMinutes(minutes);
    today.setSeconds(0); // Optionally, set seconds to 0
    today.setMilliseconds(0); // Set milliseconds to 0

    // Convert to ISO format
    const updatedDateTime = today.toISOString();
    console.log("today", updatedDateTime);
    setBusinessHours((prevState) =>
      prevState.map((item) =>
        item.day === day ? { ...item, [timeType]: updatedDateTime } : item
      )
    );
  };

  const transformTimesToObject = (times) => {
    return times.reduce((acc, curr) => {
      acc[curr.day] = {
        from: curr.openingHour,
        to: curr.closingHour,
      };
      return acc;
    }, {});
  };

  const imageHandling = (event, key) => {
    event.preventDefault();
    let previewURLs = [];
    let sendingData = [];
    let file = event.target.files;

    if (file?.length > 0) {
      Array.from(file).forEach((element) => {
        let previewUrl = URL.createObjectURL(element);
        previewURLs.push(previewUrl);
        sendingData.push(element);
      });

      setpreviewImages({ ...previewImage, [key]: previewURLs });
      setform({ ...form, [key]: sendingData });
    }
  };

  //       const removeImage =(val,key,ind)=>{
  //         const isBlob = val.startsWith('blob:');
  //         if(isBlob){
  // // remove from local
  //         setpreviewImages(prevState => {
  //           const updatedArray = [...prevState[key]]; // Copy the array
  //           updatedArray.splice(ind, 1); // Remove the element at the specified index
  //           return { ...prevState, [key]: updatedArray }; // Update the state
  //         });
  //           return
  //         }else{
  // //call remove api
  //           return
  //         }
  //       }

  const removeImage = (val, key, ind) => {
    const isBlob = val.startsWith("blob:");
    if (isBlob) {
      // Remove from local
      setpreviewImages((prevState) => {
        const updatedArray = [...prevState[key]]; // Copy the array
        updatedArray.splice(ind, 1); // Remove the element at the specified index
        return { ...prevState, [key]: updatedArray }; // Update the state
      });
    } else {
      // Call remove API
      authInstance
        .post(
          `${REACT_APP_API_URL}/deleteBusinessCertificatePic`,
          {
            fileName: val.split("/").pop(), // Extract the file name from the URL
            businessId: id, // Replace with the actual business ID
            type: key,
          },
          {
            headers: {
              Authorization: `Bearer YOUR_BEARER_TOKEN`, // Replace with your actual Bearer Token
            },
          }
        )
        .then((response) => {
          console.log("API Response:", response);
          // Remove from local after successful API call
          setpreviewImages((prevState) => {
            const updatedArray = [...prevState[key]]; // Copy the array
            updatedArray.splice(ind, 1); // Remove the element at the specified index
            return { ...prevState, [key]: updatedArray }; // Update the state
          });
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    }
  };

  const handleSelect = (place) => {
    // setLocationName(place?.label)
    setLocationName(place); // Update locationName with the selected location's description
    setform({ ...form, businessLocation: place?.label });
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId: place?.value?.place_id }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
        const location = results[0].geometry.location;
        // setCoordinates({
        //   lat: location.lat(),
        //   lng: location.lng(),
        // });
        setlat(location.lat());
        setlong(location.lng());
        // console.log(location.lat(),location.lng(),"location are here")
      }
    });
  };

  const toggleModal = () => {
    setShow(!show);
  };
  const defaultProps = {
    center: {
      lat: currentLat,
      lng: currentLong,
    },
    zoom: 8,
  };
  useEffect(() => {
    let arr = subcategorylist?.data;
    let customArr = [];
    arr?.forEach((element) => {
      // { value: 'Bakery', label: 'Bakery', isFixed: false },

      let obj = {};
      obj.value = element._id;
      obj.label = element.subCategory;
      obj.isFixed = false;
      customArr.push(obj);
    });
    setsubcatglist(customArr);
  }, [subcategorylist]);
  // useEffect(()=>{
  //       getLocationName(currentLat,currentLong)
  // },[currentLat,currentLong])

  // const getLocationName = async (lat, lng) => {
  //   const response = await fetch(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
  //   );
  //   const data = await response.json();
  //   if (data.results && data.results[0]) {
  //     setLocationName(data.results[0].formatted_address);
  //   } else {
  //     setLocationName('Unknown location');
  //   }
  // };

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.getCurrentPosition((position) => {
        setlat(position.coords.latitude);
        setlong(position.coords.longitude);
      });
    } else {
      /* geolocation IS NOT available */
    }
  }, []);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #DADADA",
      boxShadow: "none",
      minHeight: "52px",
      borderRadius: "8px",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "#fff",
      backgroundColor: "#000080", // Change background color of the selected value container
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
  };

  const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#DADADA", // Custom border color
      boxShadow: state.isFocused ? "none" : "none",
      borderRadius: "8px", // Custom border radius
      height: "50px", // Increase the height of the input
      fontSize: "16px",
    }),
    input: (provided) => ({
      ...provided,
      fontSize: "14px",
      height: "100%", // Match the height to the control
    }),
    menu: (provided) => ({
      ...provided,
      borderColor: "#DADADA", // Custom border color for suggestions container
      borderRadius: "8px", // Custom border radius for suggestions container
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the separator
    }),
    dropdownIndicator: () => ({
      display: "none", // Hide the dropdown arrow
    }),
  };
  const { businessLogo } = previewImage;

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = {
      ...form,
      businessLocation: [currentLat, currentLong],
      times: JSON.stringify(transformTimesToObject(times)),
      businessSubCategory: JSON.stringify(fixedValue),
      locationName: JSON.stringify(locationName),
    };

    const { error, value } = businessValidationSchema.validate(formData, {
      abortEarly: false,
    });
    console.log(error, "error is here checkout ");
    if (error) {
      const errors = {};
      error.details.forEach((detail) => {
        errors[detail.path[0]] = detail.message;
      });
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      let formdata = new FormData();
      value.businessId = id;
      let parms = {
        url: "adminUpdateBusiness",
        formdata: convertToFormData(value),
      };
      mutation.mutate(parms);
      // Submit the form
      // console.log('Form submitted successfully', values);
      // handle form submission logic here, like making an API call
      console.log("Form data is valid: ", value);
    }
  };
  const handleToggle = (e) => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    console.log("handleToggleworking", isOpen, e.target.checked);

    setform({
      ...form,
      isOpen24Hours: e.target.checked,
      times: "",
    });
    console.log(form);
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Edit Business</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Business"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={5} xl={4} className="view_profile mb-3 ">
              <div className="user_info text-center d-flex flex-column justify-content-center align-items-center">
                <img
                  src={businessLogo ?? Avatar}
                  className="rounded-circle img-fluid profilepic"
                  alt=""
                />
                <label
                  htmlFor="uploadprofileimg"
                  className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                >
                  Choose Picture
                </label>
                <input
                  onChange={(e) => imageHandling(e, "businessLogo")}
                  type="file"
                  id="uploadprofileimg"
                  className="d-none"
                />

                {validationErrors?.businessLogo && (
                  <p className="text-danger">
                    {validationErrors?.businessLogo}
                  </p>
                )}
              </div>
              {/* {validationErrors.businessLogo && <p className="text-danger">{validationErrors.businessLogo}</p>} */}
            </Col>
            <Col md={7} xl={8}>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        value={form?.fullName}
                        onChange={(e) =>
                          setform({ ...form, fullName: e.target.value })
                        }
                      />
                      {validationErrors?.fullName && (
                        <p className="text-danger">
                          {validationErrors?.fullName}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        value={form?.email}
                        onChange={(e) =>
                          setform({ ...form, email: e.target.value })
                        }
                      />
                      {validationErrors?.email && (
                        <p className="text-danger">{validationErrors?.email}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone no."
                        value={form?.businessPhoneNumber}
                        onChange={(e) =>
                          setform({
                            ...form,
                            businessPhoneNumber: e.target.value,
                          })
                        }
                      />
                      {validationErrors?.businessPhoneNumber && (
                        <p className="text-danger">
                          {validationErrors?.businessPhoneNumber}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  {/* <Col lg={6}>
                                        <Form.Group className="form-group mb-3">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter name" value={form?.password} onChange={(e) => setform({...form,password:e.target.value})} />
                                            {validationErrors?.password && <p className="text-danger">{validationErrors?.password}</p>}

                                        </Form.Group>
                                    </Col> */}
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Business Name"
                        value={form?.businessName}
                        onChange={(e) =>
                          setform({ ...form, businessName: e.target.value })
                        }
                      />
                      {validationErrors?.businessName && (
                        <p className="text-danger">
                          {validationErrors?.businessName}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Location</Form.Label>
                      <div className="position-relative">
                        <span className="location_icon" onClick={handleShow}>
                          <Icon
                            style={{ cursor: "pointer" }}
                            icon="ion:location"
                          />
                        </span>
                        {/* <Form.Control disabled className="pe-5" type="text" placeholder="Enter Location" value={locationName} onChange={(e) => setform({...form,businessLocation:e.target.value})}/> */}
                        <GooglePlacesAutocomplete
                          apiKey={GOOGLE_API_KEY}
                          selectProps={{
                            value: locationName,
                            styles: customStyles2,
                            placeholder: "Enter a location",
                            onChange: handleSelect,
                            getOptionLabel: (option) => option.label,
                            getOptionValue: (option) => option.value.place_id,
                          }}
                        />
                      </div>
                      {validationErrors?.businessLocation && (
                        <p className="text-danger">
                          {validationErrors?.businessLocation}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        aria-label="Select Category"
                        value={form?.businessCategory}
                        onChange={(e) =>
                          setform({ ...form, businessCategory: e.target.value })
                        }
                      >
                        <option value="select">Select Business</option>
                        {categorylist?.data?.map((val, ind) => {
                          return (
                            <option key={ind} value={val?._id}>
                              {val?.category}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {validationErrors?.businessCategory && (
                        <p className="text-danger">
                          {validationErrors?.businessCategory}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Subcategory</Form.Label>
                      <Select
                        isClearable={false}
                        value={fixedValue}
                        isMulti
                        name="colors"
                        styles={customStyles}
                        options={subcategorylistArr}
                        onChange={fixedOnChange}
                        className="multiselect"
                        classNamePrefix="select"
                      />
                      {validationErrors?.businessSubCategory && (
                        <p className="text-danger">
                          {validationErrors?.businessSubCategory}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Plan</Form.Label>
                      <Form.Select
                        aria-label="Select Category"
                        value={form?.plan}
                        onChange={(e) =>
                          setform({ ...form, plan: e.target.value })
                        }
                      >
                        <option value="select">Select Plan</option>
                        {/* {
                                                    categorylist?.data?.map((val,ind)=>{
                                                <option value={val?._id}>{val?.category}</option>
                                                    })
                                                } */}

                        <option value="Free">Free</option>
                        <option value="Basic">Basic</option>
                        <option value="Standard">Standard</option>
                        <option value="Premium">Premium</option>
                      </Form.Select>
                      {validationErrors?.plan && (
                        <p className="text-danger">{validationErrors?.plan}</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Whatsapp Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Whatsapp Link"
                        value={form?.whatsappLink}
                        onChange={(e) =>
                          setform({ ...form, whatsappLink: e.target.value })
                        }
                      />
                      {validationErrors?.whatsappLink && (
                        <p className="text-danger">
                          {validationErrors?.whatsappLink}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Instagram Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Instagram Link"
                        value={form?.instagramLink}
                        onChange={(e) =>
                          setform({ ...form, instagramLink: e.target.value })
                        }
                      />
                      {validationErrors?.instagramLink && (
                        <p className="text-danger">
                          {validationErrors?.instagramLink}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Telegram Link</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Telegram Link"
                        value={form?.telegramLink}
                        onChange={(e) =>
                          setform({ ...form, telegramLink: e.target.value })
                        }
                      />
                      {validationErrors?.telegramLink && (
                        <p className="text-danger">
                          {validationErrors?.telegramLink}
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col lg={12}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Is open 24*7</Form.Label>
                      <Form.Check
                        type="switch"
                        id="isOpenSwitch"
                        label={form?.isOpen24Hours ? "Open" : "Closed"}
                        checked={form?.isOpen24Hours}
                        onChange={handleToggle}
                      />
                    </Form.Group>
                  </Col>

                  {!form.isOpen24Hours ? (
                    <Col lg={12}>
                      <Form.Group className="form-group mb-3 timesetting_div">
                        <Form.Label>Select Business Hours</Form.Label>
                        {daysOfWeek.map((day) => (
                          <Form.Group key={day} className="form-check mb-3">
                            <Form.Check
                              type="checkbox"
                              onChange={() => handleCheckboxChange(day)}
                              checked={times.some((item) => item.day === day)}
                            />
                            <Form.Label check>
                              <span className="day_badge">{day}</span>
                              From
                              {console.log("timessss ---", times)}
                              <Form.Control
                                type="time"
                                value={
                                  times.find((item) => item.day === day)
                                    ? new Date(
                                        times.find(
                                          (item) => item.day === day
                                        ).openingHour
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })
                                    : ""
                                }
                                onChange={(e) =>
                                  handleTimeChange(
                                    day,
                                    "openingHour",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  !times.some((item) => item.day === day)
                                }
                              />
                              To
                              <Form.Control
                                type="time"
                                value={
                                  times.find((item) => item.day === day)
                                    ? new Date(
                                        times.find(
                                          (item) => item.day === day
                                        ).closingHour
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                      })
                                    : ""
                                }
                                onChange={(e) =>
                                  handleTimeChange(
                                    day,
                                    "closingHour",
                                    e.target.value
                                  )
                                }
                                disabled={
                                  !times.some((item) => item.day === day)
                                }
                              />
                            </Form.Label>
                            {validationErrors?.times && (
                              <p className="text-danger">
                                {validationErrors?.times}
                              </p>
                            )}
                          </Form.Group>
                        ))}
                      </Form.Group>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col lg={12}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Business Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Enter Business Description"
                        value={form?.businessDescription}
                        onChange={(e) =>
                          setform({
                            ...form,
                            businessDescription: e.target.value,
                          })
                        }
                      />
                      {validationErrors?.businessDescription && (
                        <p className="text-danger">
                          {validationErrors?.businessDescription}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Upload Certificate</Form.Label>
                      <Form.Control
                        multiple
                        onChange={(e) =>
                          imageHandling(e, "businessCertificate")
                        }
                        id="categoryimg"
                        name="categoryimg"
                        className="displaynone"
                        type="file"
                      />
                      <Form.Label className="fileupload" htmlFor="categoryimg">
                        <Icon
                          className="uplaodicon"
                          icon="ion:camera-outline"
                        ></Icon>
                        <h3>Upload Certificate Here</h3>
                      </Form.Label>
                      {validationErrors?.businessCertificate && (
                        <p className="text-danger">
                          {validationErrors?.businessCertificate}
                        </p>
                      )}
                    </Form.Group>
                    {previewImage["businessCertificate"]?.map((val, ind) => {
                      return (
                        <>
                          <img
                            src={val}
                            key={ind}
                            alt={`Preview ${ind}`}
                            style={{
                              width: "70px",
                              height: "60px",
                              margin: "10px",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              // console.log(" working ");
                              handleShowimg(`${val}`);
                            }}
                          />
                          <span
                            onClick={() =>
                              removeImage(val, "businessCertificate", ind)
                            }
                            style={{
                              color: "white",
                              position: "absolute",
                              marginLeft: "-20px",
                              border: "0.4px solid",
                              borderRadius: "180px",
                              background: "darkred",
                              fontWeight: "bold",
                              display: "inline-flex", // Ensures the icon is centered
                              alignItems: "center",
                              justifyContent: "center",
                              width: "20px", // Ensure the size of the container
                              height: "20px", // Ensure the size of the container
                              cursor: "pointer",
                            }}
                          >
                            <Icon icon="tabler:x" />
                          </span>
                        </>
                      );
                    })}
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-3 form-group">
                      <Form.Label>Business Photos</Form.Label>
                      <Form.Control
                        onChange={(e) => imageHandling(e, "businessPic")}
                        id="businessImage"
                        name="businessImage"
                        className="displaynone"
                        multiple={true}
                        type="file"
                      />
                      <Form.Label
                        className="fileupload"
                        htmlFor="businessImage"
                      >
                        <Icon
                          className="uplaodicon"
                          icon="ion:camera-outline"
                        ></Icon>
                        <h3>Drag & Drop Here</h3>
                      </Form.Label>
                      {validationErrors?.businessPic && (
                        <p className="text-danger">
                          {validationErrors?.businessPic}
                        </p>
                      )}
                    </Form.Group>
                    {previewImage["businessPic"]?.map((val, ind) => {
                      return (
                        <>
                          <img
                            src={val}
                            key={ind}
                            alt={`Preview ${ind}`}
                            style={{
                              width: "70px",
                              height: "60px",
                              margin: "10px",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              // console.log(" working ");
                              handleShowimg(`${val}`);
                            }}
                          />
                          <span
                            onClick={() => removeImage(val, "businessPic", ind)}
                            style={{
                              color: "white",
                              position: "absolute",
                              marginLeft: "-20px",
                              border: "0.4px solid",
                              borderRadius: "180px",
                              background: "darkred",
                              fontWeight: "bold",
                              display: "inline-flex", // Ensures the icon is centered
                              alignItems: "center",
                              justifyContent: "center",
                              width: "20px", // Ensure the size of the container
                              height: "20px", // Ensure the size of the container
                              cursor: "pointer",
                            }}
                          >
                            <Icon icon="tabler:x" />
                          </span>
                        </>
                      );
                    })}
                  </Col>
                </Row>
                {/* <Button onClick={handleFormSubmit} className="btn btn-primary px-4 mt-3 min_width140">Save</Button> */}
                <Button
                  onClick={handleFormSubmit}
                  className="btn btn-primary px-4 mt-3 min_width140"
                >
                  {" "}
                  {mutation.isPending ? (
                    <ThreeCircles
                      visible={true}
                      height="30"
                      width="100"
                      color="white"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          {/* <Link   onClick={handleClose} className="modalclose_btn"><Icon icon="ic:outline-close" /></Link> */}
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                position: "relative",
                width: "80%",
                height: "80%",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{
                  key:
                    process.env.GOOGLE_MAP_API_KE ??
                    "AIzaSyBHQWCrTWJIMYz04ZN43VyZ9xv6agXnuyk",
                }}
                center={defaultProps.center}
                zoom={defaultProps.zoom}
                onClick={(event) => {
                  console.log(event, "console events");
                  setlat(event?.lat);
                  setlong(event?.lng);
                  // getLocationName(event?.lat, event?.lng);
                }}
              >
                <AnyReactComponent lat={currentLat} lng={currentLong} />
              </GoogleMapReact>

              <button
                onClick={toggleModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "none",
                  border: "none",
                  fontSize: "30px",
                  cursor: "pointer",
                  borderWidth: 1,
                }}
              >
                &times;
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="modalimg_div" show={showimg} onHide={handleCloseimg} centered>
        <button className="remove-icon" onClick={handleCloseimg}>
          &times;
        </button>
        <Modal.Body>
          <img src={selectedImg} alt="" />
        </Modal.Body>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}
