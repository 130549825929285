import Joi from 'joi';

// Subcategory validation schema
const catgSchema = Joi.object({
  category: Joi.string()
    .trim()
    .min(2)
    .required()
    .messages({
      'string.empty': 'Category name is required',
      'string.min': 'Category must be at least 2 characters long',
      'any.required': 'Category name is required',
    }),
  categoryPic: Joi.any().optional(), // Corrected to use Joi.any().optional()
});

export default catgSchema;
