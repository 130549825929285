import React , {useState ,useEffect} from "react";
import { NavLink ,Link } from 'react-router-dom';
import logodark from "../assets/img/logodark.svg";
import { Icon } from '@iconify/react';
// import {
//     Container,
//     Row,
//     Col,
//   } from 'react-bootstrap';

  const Sidebar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    document.body.classList.toggle("no-scroll", sidebarOpen);
  }, [sidebarOpen]);

  const handleNavLinkClick = () => {
    setSidebarOpen(false);
  };

    const pages = [
{
    id: 'Dashboard',
    title: 'Dashboard',
    link: '/Dashboard',
    icon: 'mdi:view-grid-outline',
},
{
    id: 'Users',
    title: 'Users',
    link: '/Users',
    icon: 'lucide:users',
},
{
  id: 'Business',
  title: 'Business',
  link: '/Business',
  icon: 'ion:business-outline',
},
{
    id: 'Categories',
    title: 'Categories',
    link: '/Category',
    icon: 'carbon:category-new',
},
{
  id: 'Advertisement',
  title: 'Advertisement',
  link: '/Advertisement',
  icon: 'bx:file',
},
{
  id: 'Banners',
  title: 'Banners',
  link: '/Banner',
  icon: 'material-symbols:planner-banner-ad-pt-outline',
},
{
    id: 'Notifications',
    title: 'Notifications',
    link: '/Notifications',
    icon: 'mingcute:notification-line',
},
// {
//     id: 'Pages',
//     title: 'All Pages',
//     link: '/Allpages',
//     icon: 'bx:file',
// },
{
    id: 'ProfileSettings',
    title: 'Profile Settings',
    link: '/ProfileSettings',
    icon: 'solar:settings-outline',
}
    ]
    return (
      <>
        <button onClick={toggleSidebar} className="btnopen" style={{display:'none'}}>
        <Icon icon="solar:hamburger-menu-broken"></Icon>
        </button>
        <button className={sidebarOpen ? "btnclose sidebarbg-open" : "btnclose sidebarbg-closed"} style={{display:'none'}} onClick={toggleSidebar}></button>      
  
        <div className={sidebarOpen ? "sidebar sidebar-open" : "sidebar sidebar-closed"}>
          <div className='sidebarlogo_div'>
            <Link to="/Dashboard"><img src={logodark} alt="" /></Link>
          </div>
          <div className="sidebarouter">
          {pages.map(page => (
              <NavLink key={page.id}
                to={page.link}
                className="navitem"
                onClick={handleNavLinkClick}
              >
                <Icon icon={page.icon}/>
                {page.title}
              </NavLink>
          ))}
          </div>
        </div>
      </>
    );
  };

export default Sidebar;