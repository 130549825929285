import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; // Import Cookies from js-cookie package

export default function PrivateRoutes({ children }) {
    const navigate = useNavigate();
    useEffect(() => {
        let token = Cookies.get("token");
        if (!token) {
            navigate("/");
        }
       
    }, [navigate]); // Add navigate to the dependency array

    return (
        <div>{children}</div>
    );
}
