import Joi from "joi";

const businessValidationSchema = Joi.object({
  businessLogo: Joi.any().required().messages({
    "any.required": "Business logo is required",
  }),
  businessName: Joi.string().required().messages({
    "string.empty": "Business name is required",
    "any.required": "Business name is required",
  }),
  businessPhoneNumber: Joi.string().required().messages({
    "string.empty": "Business phone number is required",
    "any.required": "Business phone number is required",
  }),
  countryCode: Joi.string().optional(),
  isOpen: Joi.boolean().optional(),
  businessDescription: Joi.any().required().messages({
    "any.required": "Description is required",
  }),
  plan: Joi.string().required().messages({
    "string.empty": "Plan is required",
    "any.required": "Plan is required",
  }),
  businessCertificate: Joi.any().required().messages({
    "any.required": "Business certificate  is required",
  }),
  businessCategory: Joi.string().required().messages({
    "string.empty": "Business category is required",
    "any.required": "Business category is required",
  }),
  // businessSubCategory: Joi.any().optional(),
  businessSubCategory: Joi.string().required().messages({
    "string.empty": "Business sub category is required",
    "any.required": "Business sub category is required",
  }),
  businessHour: Joi.object().optional(),
  businessPic: Joi.any().optional(),
  businessPic: Joi.any().required().messages({
    "any.required": "Business pic is required",
  }),
  businessLocation: Joi.array().optional(),
  isOpen24Hours: Joi.boolean().optional(),
  times: Joi.any().optional(),
  profileStep: Joi.number().optional(),
  whatsappLink: Joi.string().allow("").optional(),
  telegramLink: Joi.string().allow("").optional(),
  instagramLink: Joi.string().allow("").optional(),
  fullName: Joi.string().required().messages({
    "string.empty": "Full name is required",
    "any.required": "Full name is required",
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": "Email is required",
      "any.required": "Email is required",
      "string.email": "Please enter a valid email address",
    }),
  phoneNumber: Joi.string().optional(),
  businessImage: Joi.any().allow(),
  password: Joi.any().required().messages({
    "any.required": "password is required",
  }),
  locationName: Joi.any().allow(),
});

export default businessValidationSchema;
