const Joi = require('joi');

const bannerschema = Joi.object({
  bannerImg: Joi.any().optional(),
  businessId: Joi.string()
    .alphanum()
    .min(3)
    .max(30)
    .required()
    .messages({
      'string.alphanum': 'Business ID must only contain alphanumeric characters',
      'string.min': 'Business ID must be at least 3 characters long',
      'string.max': 'Business ID must be at most 30 characters long',
      'any.required': 'Business ID is required',
      'string.empty': 'Business ID cannot be empty'
    }),
  message: Joi.string()
    .min(5)
    .max(500)
    .required()
    .messages({
      'string.min': 'Message must be at least 5 characters long',
      'string.max': 'Message must be at most 500 characters long',
      'any.required': 'Message is required',
      'string.empty': 'Message cannot be empty'
    })
});

module.exports = bannerschema;
