import { authInstance, formDataInstance, noAuthInstance } from "./configuraton"

// non auth post function
// passwordService.js

import axios from 'axios';
import Cookies from 'js-cookie';
import { REACT_APP_API_URL } from "../../local";

/**
 * Function to change the user password
 * @param {string} newPassword - The new password
 * @param {string} confirmPassword - The confirmation of the new password
 * @returns {Promise} - Axios response promise
 */
export const changePassword = async (newPassword, confirmPassword) => {
  // Define the data to be sent in the request body
  const data = {
    newPassword,
    confirmPassword
  };

  // Get the user token from cookies
  const token = Cookies.get('resetToken');

  // Make the POST request
  try {
    const response = await axios.post(REACT_APP_API_URL+"resetPassword", data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    return response.data; // Return the response data
  } catch (error) {
    // Handle errors by throwing them to be caught by the caller
    throw error;
  }
};



export const NonAuthPostAPi = async(url,params)=>{
    return new Promise((resolve, reject) => {
        noAuthInstance.post(url,params).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}


// non auth post function
export const AuthPost = async(url,params)=>{
    console.log(params,"params ==>")
    return new Promise((resolve, reject) => {
        authInstance.post(url,params).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}


export const AuthPostdelete = async(url,params)=>{
    return new Promise((resolve, reject) => {
        authInstance.delete(url,params).then(res=>{
            resolve(res.data)
        }).catch(err=>{
            reject(err)
        })
    })
}





export const AuthgetApi = async (url) => {
    try {
        const response = await authInstance.get(url);
        return response.data;
    } catch (error) {
        throw error; // Rethrow the error to propagate it to the caller
    }
};

export const authFormdataPut = async (url,formdata) => {
    try {
        // console.log(adminId,"---->inside repos");
        const response = await formDataInstance.put(url,formdata);
        return response.data;
    } catch (error) {
        throw error; // Rethrow the error to propagate it to the caller
    }
};

export const authFormdataPost = async (data) => {
    try {
        // console.log(adminId,"---->inside repos");
        const response = await formDataInstance.post(data?.url,data?.formdata);
        return response.data;
    } catch (error) {
        throw error; // Rethrow the error to propagate it to the caller
    }
};
// addUser