import axios from "axios";
import Cookies from "js-cookie";
import { REACT_APP_API_URL } from "../../local";


const BaseURL = REACT_APP_API_URL
// Instance for requests without authentication
export const noAuthInstance = axios.create({
  baseURL:BaseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

// Instance for requests with token
export const authInstance = axios.create({
  baseURL:BaseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

// Instance for requests with form data
export const formDataInstance = axios.create({
  baseURL: BaseURL,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});

// Attach token from cookies to authInstance
authInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Attach token from cookies to authInstance
formDataInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
